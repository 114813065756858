const notificationsActions = {
  NEW: "NEW",
  READ: "READ",

  notificationsNew: data => {
    return {
      type: notificationsActions.NEW,
      data
    };
  },
  notificationsRead: () => {
    console.log('notificationsRead')
    return {
      type: notificationsActions.READ
    };
  }
};

export default notificationsActions;
