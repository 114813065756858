import React, { useState } from "react";
import { locakscreenBack } from "helper/constant";
import IntlMessages from "util/intlMessages";
import Api from "api/api";
import SweetAlert from 'react-bootstrap-sweetalert';
import { injectIntl } from "react-intl";
import Session from "util/Session";
import { Spinner } from "reactstrap";

const Buy = props => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState(false);
  const [error, setError] = useState({});
  const [packName, setPackName] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientCpfOrCnpj, setClientCpfOrCnpj] = useState('');

  const loginContainer = {
    backgroundImage: `url(${locakscreenBack})`,
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const showForm = async (packName) => {
    setPackName(packName);
    setForm(true);
  }

  const tryBuy = async () => {
    setLoading(true);
    if (isValid()) {
      setForm(false);
      const wallet = Session.getWallet();
      const req = await Api.post('sales', {
        address: wallet.address,
        packName,
        clientName,
        clientCpfOrCnpj,
      })
      if (!req.error) {
        window.open(req.data.invoiceUrl, "_self");
        return;
      }
    }
    setLoading(false);
  }

  const isValid = () => {
    if (clientName.trim().length === 0) {
      setError({
        name: <IntlMessages id="invalid_field" />
      });
      return false;
    }
    if (clientCpfOrCnpj.trim().length === 0) {
      setError({
        cpf_or_cnpj: <IntlMessages id="invalid_field" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid pricing-page" style={loginContainer}>
      <SweetAlert
        showCancel
        show={form}
        title={intl.formatMessage({ id: `buy.data` })}
        onConfirm={tryBuy}
        onCancel={() => setForm(false)}
        cancelBtnCssClass="sweet-alert-cancle-button"
        confirmBtnCssClass="sweet-alert-confirm-button" >
        <form className="pa-24">
          <div className="form-group">
            <label>
              <IntlMessages id="buy.name" />
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              value={clientName}
              onChange={e => setClientName(e.target.value)}
            />
            <Error field="name" />
          </div>
          <div className="form-group">
            <label>
              <IntlMessages id="buy.cpf_or_cnpj" />
            </label>
            <input
              type="text"
              className="form-control react-form-input"
              value={clientCpfOrCnpj}
              onChange={e => setClientCpfOrCnpj(e.target.value)}
            />
            <Error field="cpf_or_cnpj" />
          </div>
        </form>
      </SweetAlert>
      <button
        className="page-home-button"
        onClick={() => props.history.goBack()}
      ><i className="fas fa-arrow-left"></i></button>
      <div className="Pricing-title text-center"><IntlMessages id="wallet.buy" /></div>
      <div className="pricing-detail">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="pricing-card ptb-24">
                <div className="fs-34">Developer</div>
                <div className="fs-16 mb-16"><IntlMessages id="buy.developer_desc" /></div>
                <div className="pink-highlight mb-20">
                  <span className="fs-34"> 99 R$ </span>
                </div>
                <div className="list fs-14">
                  <div className="mb-16">1.000 Créditos Bywise</div>
                  <div className="mb-16"><IntlMessages id="buy.email_suport" /></div>
                </div>
                <div>
                  <button disabled={loading} className="pricing-button" onClick={() => showForm('Developer')}>
                    {loading && <Spinner color="primary" />}
                    {!loading && <IntlMessages id="buy.get_started" />}
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="pricing-white-card ptb-24">
                <div className="fs-34">Business</div>
                <div className="fs-16 mb-16"><IntlMessages id="buy.business_desc" /></div>
                <div className="pink-highlight mb-20">
                  <span className="fs-34"> 849 R$ </span> {" "} 15% OFF
                </div>
                <div className="list fs-14">
                  <div className="mb-16">10.000 Créditos Bywise</div>
                  <div className="mb-16"><IntlMessages id="buy.premium_suport" /></div>
                </div>
                <div>
                  <button disabled={loading} className="pricing-button" onClick={() => showForm('Business')}>
                    {loading && <Spinner color="primary" />}
                    {!loading && <IntlMessages id="buy.get_started" />}
                  </button>
                </div>
                <div className="trial-info mt-7"><IntlMessages id="buy.recommended" /></div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="pricing-card ptb-24">
                <div className="fs-34">Enterprise</div>
                <div className="fs-16 mb-16"><IntlMessages id="buy.enterprise_desc" /></div>
                <div className="pink-highlight mb-20">
                  <span className="fs-34"> 6.900 R$</span> {" "} 30% OFF
                </div>
                <div className="list fs-14">
                  <div className="mb-16">100.000 Créditos Bywise</div>
                  <div className="mb-16"><IntlMessages id="buy.premium_suport" /></div>
                </div>
                <div>
                  <button disabled={loading} className="pricing-button" onClick={() => showForm('Enterprise')}>
                    {loading && <Spinner color="primary" />}
                    {!loading && <IntlMessages id="buy.get_started" />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Buy);
