import React from "react";
import { withRouter } from "react-router-dom";
import { Spinner } from "reactstrap";

const Loading = props => {
  return (<>
    {props.loading && <div className="text-center mt-5">
      <Spinner
        color="primary"
        style={{
          height: '3rem',
          width: '3rem'
        }}
      >
        Loading...
      </Spinner>
    </div>}
  </>);
};

export default withRouter(Loading);