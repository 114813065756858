import styled from "styled-components";

export const TokenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img-sm {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
  }
  .type-icon {
    font-size: 30px;
    padding: 3px 10px;
  }
  .title-text {
    line-height: 10px;
  }
  .measure-text {
    color: ${props => (props.last24Hrs >= 0 ? "#77A361" : "#CD6889")};
    font-size: 20px;
  }
`;
