import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import Auth from "api/auth";

export const ProtectedRoute = ({ authData, children }) => {
  if (!Auth.isLogged()) {
    return <Redirect to={"/signin"} />;
  }
  return <Fragment>{children}</Fragment>;
};
