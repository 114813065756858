import moment from "moment";

export const getUptime = (created) => {
  let uptime = (new Date().getTime()) / 1000 - created;
  let label = '';
  if (uptime < 60) {
      label = 'seconds';
  } else if (uptime < 60 * 60) {
      uptime = uptime / (60);
      label = 'minutes';
  } else if (uptime < 60 * 60 * 24) {
      uptime = uptime / (60 * 60);
      label = 'hours';
  } else {
      uptime = uptime / (60 * 60 * 24);
      label = 'days';
  }
  return `${Math.floor(uptime)} ${label}`;
}

export const randomUUID = () => {
  return (
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1) +
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  );
};

export const subtaskTicket = () => {
  return (
    "#" +
    Math.floor((1 + Math.random()) * 0x1000)
      .toString(16)
      .substring(1) +
    Math.floor((1 + Math.random()) * 0x1000)
      .toString(16)
      .substring(1)
  );
};

export const dateFormat = date => {
  return moment(date).format("MMMM Do, h:mm a");
};

export const onlyDate = date => {
  return moment(date).format("MMM Do");
};

export const datesWithYear = date => {
  return moment(date).format("MMM Do YYYY");
};
