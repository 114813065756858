import CryptoJS from "crypto-js";
import Session from "util/Session";
import { Wallet } from "@bywise/web3";

const authActions = {
  LOGIN: "LOGIN",

  login: data => {
    let seed;
    if (data.remember) {
      seed = data.seed;
    } else {
      const password = CryptoJS.SHA256(data.password).toString();
      seed = CryptoJS.AES.encrypt(data.seed, password).toString();
    }
    const wallet = new Wallet({ seed: data.seed });
    Session.setWallet(wallet);
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: seed
    };
  },
};

export default authActions;
