import React from "react";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";
import { Col, Row } from "reactstrap";

const Community = (props) => {

  return (
    <div className="page-padding">
      <PageTitle title="community" />
      <div className="plr-15">
        <Row>
          <Col><div className="roe-card-style">
            <div className="roe-card-header">
              <span className="hash"># </span>
              <IntlMessages id="community" />
            </div>
            <div className="roe-card-body">
              <div className="mt-4">
                <a href="https://forms.gle/j6nbwfHBZVm2LeWH8" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i> Mini Curso Web3 na Bywise</a>
              </div>
              <div className="mt-4">
                <a href="https://discord.gg/tbJgTDcYaQ" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i> Discord - <IntlMessages id="community_discord" /></a>
              </div>
              <div className="mt-4">
                <a href="https://t.me/bywise_global" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram"></i> Telegram</a>
              </div>
              <div className="mt-4">
                <a href="https://www.instagram.com/bywise_global/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i> Instagram</a>
              </div>
              <div className="mt-4">
                <a href="https://www.linkedin.com/company/devel-blockchain-company" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i> Linkedin</a>
              </div>
            </div>
          </div></Col>
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Community);
