import styled from "styled-components";

const FeedWrapper = styled.div`
  .big-icon {
    font-size: 1.5rem;
    margin: auto;
  }
  .img-sm {
    width: 41px;
    height: 41px;
    border-radius: 5px;
    object-fit: cover;
  }
`;

export default FeedWrapper;
