import React, { useState } from "react";
import PageTitle from "components/common/PageTitle";
import IntlMessages from "util/intlMessages";
import { BywiseHelper, Tx, TxType } from "@bywise/web3";
import Session from "util/Session";
import SweetAlert from 'react-bootstrap-sweetalert'
import { Spinner } from "reactstrap";
import Loading from "components/common/Loading";
import { useLocation } from "react-router-dom";
import BigNumber from "bignumber.js";

const buttonBack = {
  backgroundColor: '#563c91',
  color: 'white'
};

const Send = (props) => {
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState({});
  const [errorAlert, setErrorAlert] = useState('');
  const [successAlert, setSuccessAlert] = useState('');
  const [loading, setLoading] = useState(false);

  const { search } = useLocation();
  const parans = new URLSearchParams(search);
  let tokenSymbol = 'BWS';
  let contractAddress = '';
  let decimals = 0;
  if (parans.get('symbol')) {
    tokenSymbol = parans.get('symbol');
  }
  if (parans.get('address')) {
    contractAddress = parans.get('address');
  }
  if (parans.get('decimals')) {
    decimals = parseInt(parans.get('decimals'));
  }

  const isValid = () => {
    if (!BywiseHelper.isValidAddress(address)) {
      setError({
        address: <IntlMessages id="invalid_address" />
      });
      return false;
    }
    if (!BywiseHelper.isValidAmount(amount)) {
      setError({
        amount: <IntlMessages id="invalid_amount" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const handleSend = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (isValid()) {
      const web3 = await Session.getWeb3();
      const wallet = Session.getWallet();
      const infoChain = Session.getInfoChain();
      const hasMarket = false;
      const market = 'BWS1MCc793dE4370a72c80C6dfF384cb52125C86B96632a7c';

      try {
        let tx;
        if (contractAddress) {
          const weiAmount = new BigNumber(amount).multipliedBy(new BigNumber(10 ** decimals)).toFixed(0);

          if (hasMarket) {
            let tx = new Tx();
            tx.chain = infoChain.chain;
            tx.version = "2";
            tx.from = [wallet.address];
            tx.to = [contractAddress, market];
            tx.amount = ['0', '0'];
            tx.type = TxType.TX_CONTRACT_EXE;
            tx.foreignKeys = [];
            tx.data = [
              {
                method: 'approve',
                inputs: [market, weiAmount],
              },
              {
                method: 'transfer',
                inputs: [contractAddress, address, weiAmount],
              },
            ]
            tx.created = Math.floor(Date.now() / 1000);
            tx.fee = (await web3.transactions.estimateFee(tx)).feeUsed;
            tx.hash = tx.toHash();
            tx.sign = [await wallet.signHash(tx.hash)];
          } else {
            tx = await web3.transactions.buildSimpleTx(wallet, infoChain.chain, contractAddress, '0', TxType.TX_CONTRACT_EXE, [
              {
                method: 'transfer',
                inputs: [address, weiAmount],
              }
            ]);
          }
        } else {
          tx = await web3.transactions.buildSimpleTx(wallet, infoChain.chain, address, amount, TxType.TX_NONE);
        }
        const error = await web3.transactions.sendTransaction(tx);
        if (error) {
          setErrorAlert(error)
        } else {
          const output = await web3.transactions.waitConfirmation(tx.hash);
          if (output.status === 'mined' || output.status === 'confirmed') {
            setSuccessAlert(tx.hash);
          } else {
            setErrorAlert(output.output.error)
          }
        }
      } catch (err) {
        setErrorAlert(err.message)
      }
    }
    setLoading(false);
  };

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="page-padding">
      <Loading />
      <SweetAlert
        title="Error"
        onConfirm={() => setErrorAlert('')}
        show={errorAlert !== ''}
        confirmBtnCssClass="sweet-alert-confirm-button"
      >{errorAlert}</SweetAlert>
      <SweetAlert
        success
        show={successAlert !== ''}
        title="Success"
        onConfirm={() => setSuccessAlert('')}
        confirmBtnCssClass="sweet-alert-confirm-button"
      ><strong>TxId: </strong><a href={`${Session.getInfoChain().explorer}/tx/${successAlert}`} target="_blank" rel="noopener noreferrer">{successAlert}</a></SweetAlert>
      <PageTitle title="send"
        breadCrumb={[
          {
            name: "wallet.title"
          },
          {
            name: "send"
          }
        ]} />

      <div className="plr-15">
        <div className="roe-card-style">
          <div className="roe-card-header">
            <span className="hash"># </span>
            <span><IntlMessages id="send" /> {tokenSymbol}</span>
          </div>
          <div className="roe-card-body">
            <form>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label">
                  <IntlMessages id="address" />
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                  />
                  <Error field="address" />
                </div>
              </div>

              <div className="form-group row">
                <label className="col-sm-4 col-form-label">
                  <IntlMessages id="amount" />
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={amount}
                    onChange={e => setAmount(e.target.value)}
                  />
                  <Error field="amount" />
                </div>
              </div>

              <button
                disabled={loading}
                style={buttonBack}
                type="submit"
                className="btn form-button"
                onClick={handleSend}
              >
                {loading && <Spinner color="primary" />}
                {!loading && <IntlMessages id="send" />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Send;
