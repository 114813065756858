import React, { useState } from "react";
import HeaderWrapper from "./header.style";
import { withRouter } from "react-router-dom";
import Session from "util/Session";
import Auth from "api/auth";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import IntlMessages from "util/intlMessages";
import SweetAlert from "react-bootstrap-sweetalert";
import { injectIntl } from "react-intl";
import { compose } from "redux";
import Api from "api/api";

const Header = props => {
  const { drawerMiniMethod, mini, intl } = props;
  const [web3Status, setWeb3Status] = useState('try');
  const [inputPrompt, setInputPrompt] = useState(false);
  if (!Auth.isLogged()) {
    return <div></div>;
  }

  const info = Auth.getInfo();
  const infoChain = Session.getInfoChain();
  const address = info.defaultAddress.address;

  const logout = () => {
    Session.clear();
    Auth.logout(false);
    props.history.push("/signin")
  }

  const setWallet = async (address) => {
    Session.setWallet(address.seed);
    window.location.reload(false);
  }

  const setChain = async (infoChain) => {
    Session.setInfoChain(infoChain)
    window.location.reload(false);
  }

  const addChain = async (chainName) => {
    let req = await Api.post(`watch-blockchains`, {
      chain: chainName,
    });
    if (!req.error) {
      req = await Api.get(`blockchains`);
      if (req.error) {
        return;
      }
      info.chains = req.data;
      Auth.saveInfo(info);
      for (let i = 0; i < info.chains.length; i++) {
        const infoChain = info.chains[i];
        if (infoChain.chain === chainName) {
          setChain(infoChain);
        }
      }
    }
  }

  Session.getWeb3().then(web3 => {
    if (web3.network.isConnected) {
      setWeb3Status('connected');
    } else {
      web3.network.tryConnection().then(() => {
        if (web3.network.isConnected) {
          setWeb3Status('connected');
        } else {
          setWeb3Status('failed');
        }
      })
    }
  }).catch(() => {
    setWeb3Status('failed');
  })

  return (
    <HeaderWrapper {...props}>
      <div className="headerBack">
        <div className="flex-x align-center">
          <div className="drawer-handle-arrow">
            {mini ? (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            ) : (
              <button
                className="top-header-icon"
                onClick={() => drawerMiniMethod()}
              >
                <i className="fas fa-bars"></i>
              </button>
            )}
          </div>
          <div
            className="mini-drawer-menu-icon"
            onClick={() => drawerMiniMethod()}
          >
            <i className="fas fa-bars" />{" "}
            <span className="app-name fs-16 bold-text">{"Bywise"}</span>
          </div>
          <div className="pl-10 flex-1">
          </div>
          <div className="pl-10">
            <button id="wallet" className="top-header-button">
              <span className="mr-1">{`${address.substring(0, 10)}...${address.substring(address.length - 5)}`}</span>
              <i className="fa fa-chevron-down"></i>
            </button>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="wallet"
              trigger="legacy"
            >
              <PopoverBody>
                {info.addresses.map((addr, i) => (<div
                  key={`addr-${i}`}
                  className="roy-menu-list"
                  onClick={() => setWallet(addr)}
                >{`${addr.address.substring(0, 10)}...${addr.address.substring(addr.address.length - 5)}`}</div>))}
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          <div className="pl-10">
            <button id="chain" className="top-header-button">
              <span className="mr-1">{`${infoChain.name}`}</span>
              {web3Status === 'try' ? "🟠" : (web3Status === 'failed' ? "🔴" : "🟢")}
            </button>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="chain"
              trigger="legacy"
            >
              <PopoverBody>
                {info.chains.map((infoChain, i) => (<div
                  key={`chain-${i}`}
                  className="roy-menu-list"
                  onClick={() => setChain(infoChain)}
                >{`${infoChain.name}`}</div>))}
                <div className="roy-menu-list" onClick={() => setInputPrompt(!inputPrompt)} >
                  <IntlMessages id="header.add_chain" />
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          <div className="pl-10">
            <button className="top-header-icon" onClick={() => props.history.push('/support')}>
              <i className="fa fa-question"></i>
            </button>
          </div>
          <div className="pl-10">
            <button id="user" className="top-header-icon">
              <i className="fa fa-user"></i>
            </button>
            <UncontrolledPopover
              className="roy-menu"
              innerClassName="roy-inner-content"
              placement="bottom-end"
              target="user"
              trigger="legacy"
            >
              <PopoverBody>
                <div
                  className="roy-menu-list"
                  onClick={() => props.history.push("/wallet")}
                ><IntlMessages id="wallet" /></div>
                <div className="roy-menu-list" onClick={() => props.history.push('/support')} >
                  <IntlMessages id="help_and_support" />
                </div>
                <div
                  className="roy-menu-list"
                  onClick={logout}
                ><IntlMessages id="logout" /></div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
      </div>
      <SweetAlert
        input
        showCancel
        show={inputPrompt}
        cancelBtnBsStyle="default"
        confirmBtnCssClass="sweet-alert-confirm-button"
        cancelBtnCssClass="sweet-alert-cancle-button"
        customClass="sweet-alert-wrapper"
        title={intl.formatMessage({ id: `header.add_chain` })}
        onConfirm={data => {
          addChain(data);
          setInputPrompt(false);
        }}
        onCancel={() =>
          setInputPrompt(false)
        }
        className="input-sweet-alert"
      ><IntlMessages id="header.write_chain_id" /></SweetAlert>
    </HeaderWrapper>
  );
};

export default compose(
  injectIntl,
  withRouter,
)(Header);
