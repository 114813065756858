import React from "react";
import FeedWrapper from "./feed.style";
import IntlMessages from "util/intlMessages";

//const docs = [
//  { title: "Quick Start", text: "Get running in minutes", url: "https://docs.bywise.org" },
//  { title: "What is Bywise?", text: "The blockchain business network", url: "https://docs.bywise.org" },
//  { title: "API Documentation", text: "API reference for Bywise", url: "https://docs.bywise.org" },
//]

const docs = [];

const Feed = () => {
  return (
    <FeedWrapper className="fill-height">
      <div className="card roe-shadow-2 fill-height">
        <div className="card-body">
          <div className="flex-x align-center mb-9">
            <h4 className="mb-0 fs-18 header"><IntlMessages id="dashboard.announcements" /></h4>
          </div>
          <hr />
          <div className="text-center grey--text pt-4 pb-4">
            <p><IntlMessages id="coming_soon" /></p>
          </div>
          {docs.map(doc => <a key={doc.title} href={doc.url} target="_blank" rel="noopener noreferrer" className="flex-x ptb-15 border-bottom cursor-pointer c-text-dark">
            <div className="big-icon">
              <i className="far fa-file-alt" />
            </div>
            <div className="mlr-10 flex-1">
              <p className="fs-15 demi-bold-text">{doc.title}</p>
              <small className="">{doc.text}</small>
            </div>
            <div className="m-auto">
              <i className="fa fa-chevron-right" />
            </div>
          </a>)}
        </div>
      </div>
    </FeedWrapper>
  );
};

export default Feed;
