import Dashboard from "views/Dashboard";
import IDE from "views/IDE";
import Send from "views/Send";
import Support from "views/Support";
import Wallet from "views/Wallet";
import Community from "views/Community";

const dashboardRoutes = [
    { path: "/dashboard", component: Dashboard },
    { path: "/wallet", component: Wallet },
    { path: "/send", component: Send },
    { path: "/ide", component: IDE },
    { path: "/support", component: Support },
    { path: "/community", component: Community },
];

export default dashboardRoutes;
