import React from "react";
import { TokenWrapper } from "./TokenItem.style";
import { errorback } from "./../../helper/constant";

const TokenItem = ({ token }) => {
  const { name, symbol, amount, price, icon, last24Hrs } = token;
  return (
    <TokenWrapper last24Hrs={last24Hrs}>
      <div className="type-icon">
        <img src={icon ? icon : errorback} className="img-sm" alt={name} />
      </div>
      <div className="mr-2 ml-2">
        <p className="title-text">{name}</p>
        <div>
          <span className="fs-20 bold-text pt-1">{amount} {symbol}</span>
          {price !== 0 && <span className="ml-2">({(price * amount).toFixed(2)}) R$</span>}
        </div>
      </div>
      {last24Hrs !== 0 && <div className="mr-2 ml-2 measure-text">
        <i className={`fas ${last24Hrs >= 0 ? "fa-arrow-up" : "fa-arrow-down"}`} />
        <span className="ml-1">{(last24Hrs * 100).toFixed(2)} %</span>
      </div>}
    </TokenWrapper>
  );
}

export default TokenItem;
