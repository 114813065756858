import Enlang from "./entries/en-US";
import PTLang from "./entries/pt-BR";
import { addLocaleData } from "react-intl";

const AppLocale = {
  pt: PTLang,
  en: Enlang
};
addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.en.data);

export default AppLocale;
