export default class Auth {
    static isLogged = () => {
        return localStorage.getItem('token') != null
    }

    static saveToken = (token) => {
        localStorage.setItem('token', JSON.stringify(token))
    }

    static getToken = () => {
        try {
            var token = localStorage.getItem('token')
            if (token) {
                return JSON.parse(token)
            }
        } catch (err) {
        }
        return '';
    }

    static logout = (reload = true) => {
        localStorage.removeItem('token');
        localStorage.removeItem('info-user');
        if(reload) {
            window.location.reload(false);
        }
    }

    static saveInfo = (info) => {
        localStorage.setItem('info-user', JSON.stringify(info))
    }

    static getPass = () => {
        try {
            var user = localStorage.getItem('info-pass')
            if (user) {
                return JSON.parse(user)
            }
        } catch (err) {
        }
        return '';
    }

    static rememberPass = (data) => {
        localStorage.setItem('info-pass', JSON.stringify(data))
    }

    static removePass = () => {
        localStorage.removeItem('info-pass');
    }

    static getInfo = () => {
        try {
            var info = localStorage.getItem('info-user')
            if (info) {
                return JSON.parse(info)
            }
        } catch (err) {
        }
        return '';
    }
}