import notificationsActions from "./actions";

import { warning } from './../../helper/constant'

const initState = {
  notifications: localStorage.getItem("notifications") ? localStorage.getItem("notifications") : [{
    icon: warning,
    name: "Remember",
    text: "Backup Secret Phrase on a paper",
    created: new Date().getTime(),
    read: false,
  }]
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case notificationsActions.NEW:
      let notifications = state.notifications.filter((n, i) => i < 5);
      notifications = notifications.reverse();
      notifications.push(action.data);
      notifications = notifications.reverse();
      return {
        ...state,
        notifications: notifications
      };
    case notificationsActions.READ:
      return {
        ...state,
        notifications: state.notifications.map(n => ({ ...n, read: true })),
      };
    default:
      return state;
  }
}
