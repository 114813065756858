import React, { Component } from "react";
import { connect } from "react-redux";
import { bywiseIcon, loginBack, 
//  pepita
 } from "helper/constant";
import IntlMessages from "util/intlMessages";
import TransactionsTable from "components/common/TransactionsTable";
import Session from "util/Session";
import Loading from "components/common/Loading";
import { ProfileLockScreen } from "helper/constant";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { Spinner } from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import FileBase64 from 'react-file-base64';
import TokenItem from "components/tokenItem/TokenItem";
import Api from "api/api";
import { injectIntl } from "react-intl";
import { toast } from "react-toastify";


const Back = {
  backgroundImage: `url(${loginBack})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat"
};
const titleStyle = {
  background: "#563c91",
  color: "white",
  fontWeight: 600
};
const activeColor = {
  color: "#563c91"
};
const profileTabLink = {
  borderBottom: `2px solid`,
  borderColor: "#563c91"
};

class Wallet extends Component {

  constructor(props) {
    super(props);
    this.state = {
      errorAlert: '',
      successAlert: '',
      infoLoading: false,
      inputPrompt: false,
      name: '',
      bio: '',
      url: '',
      photo: '',
      loading: true,
      wallet: true,
      history: false,
      info: false,
      txs: [],
      tokens: [],
      addressInfo: {
        name: '',
        address: '',
        balance: '0',
        photo: '',
        url: '',
        bio: '',
        publicKey: '',
        txCount: '',
      },
    }
  }

  componentDidMount = async () => {
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    const info = await web3.wallets.getWalletInfo(wallet.address, infoChain.chain);
    const txCount = await web3.transactions.countTxs({
      chain: infoChain.chain,
      find: { searchBy: 'address', value: wallet.address }
    })

    const txs = await web3.transactions.getTxs(infoChain.chain, { find: { searchBy: 'address', value: wallet.address } });

    await this.updateTokens();

    this.setState({
      name: info.name,
      bio: info.bio,
      url: info.url,
      chain: infoChain.chain,
      txs: txs,
      loading: false,
      addressInfo: {
        ...info,
        txCount,
      }
    })
  }

  buyAction = (token) => {
    if (token.symbol === 'BWS') {
      this.props.history.push('/pricing');
    } else {
      window.open('https://www.bloco1.com.br/', '_blank', 'noreferrer');
    }
  }

  sendAction = (token) => {
    if (token.symbol === 'BWS') {
      this.props.history.push('/send');
    } else {
      this.props.history.push(`/send?symbol=${token.symbol}&address=${token.address}&decimals=${token.decimals}`);
    }
  }

  activePanel = data => {
    if (data === "history") {
      this.setState({
        history: true,
        info: false,
        wallet: false,
      })
    } else if (data === "info") {
      this.setState({
        history: false,
        info: true,
        wallet: false,
      })
    } else if (data === "wallet") {
      this.setState({
        history: false,
        info: false,
        wallet: true,
      })
    }
  };

  updateTokens = async () => {
    const web3 = await Session.getWeb3();
    const infoChain = Session.getInfoChain();
    const wallet = Session.getWallet();
    const info = await web3.wallets.getWalletInfo(wallet.address, infoChain.chain);
    const req = await Api.get(`contracts`);
    if (req.error) {
      return;
    }
    const contracts = req.data;
    const tks = [];
    tks.push({ name: this.props.intl.formatMessage({ id: `bywise_token` }), symbol: "BWS", amount: info.balance, price: 0, icon: bywiseIcon, address: "", last24Hrs: 0, decimals: 0 });
    if (infoChain.chain === 'mainnet')
    //tks.push({ name: 'Pepita Utility Token', symbol: 'Pepita', amount: '0', price: 0, icon: pepita, address: 'BWS1MC8Db1fd3E10efa22944A260a9bF928E323EC3E03C997', last24Hrs: 0, decimals: 18 });
    //tks.push({ name: 'Prodomos Utility Token', symbol: 'Prodomos', amount: '0', price: 0, icon: prodomus, address: 'BWS1MC7777792D98EbAe07d180F5f7991fE16307525b8313b', last24Hrs: 0, decimals: 18 });
    //tks.push({ name: 'Dhahab Sports', symbol: 'DHS', amount: '0', price: 0, icon: dhahabsports, address: 'BWS1MC7777792D98EbAe07d180F5f7991fE16307525b8313b', last24Hrs: 0, decimals: 18 });
    //tks.push({ name: 'Dhabi Coin', symbol: 'DBC', amount: '0', price: 0, icon: dhabicoin, address: 'BWS1MC7777792D98EbAe07d180F5f7991fE16307525b8313b', last24Hrs: 0, decimals: 18 });
    //tks.push({ name: 'Riberman Utility Token', symbol: 'Riberman', amount: '0', price: 0, icon: riberman, address: 'BWS1MC7777792D98EbAe07d180F5f7991fE16307525b8313b', last24Hrs: 0, decimals: 18 });

    for (let i = 0; i < contracts.length; i++) {
      const contract = contracts[i];

      let output = await web3.contracts.readContract(infoChain.chain, contract.address, 'name', []);
      if (!output) {
        toast.error(`Error - Can't read contract`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const name = output.output;

      output = await web3.contracts.readContract(infoChain.chain, contract.address, 'balanceOf', [wallet.address]);
      if (!output) {
        toast.error(`Error - Can't read contract`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      let balance = parseFloat(output.output);

      output = await web3.contracts.readContract(infoChain.chain, contract.address, 'symbol', []);
      if (!output) {
        toast.error(`Error - Can't read contract`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const symbol = output.output;

      output = await web3.contracts.readContract(infoChain.chain, contract.address, 'decimals', []);
      if (!output) {
        toast.error(`Error - Can't read contract`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      const decimals = (output.output);
      balance = parseFloat((balance / (10 ** decimals)).toPrecision(8));

      tks.push({ name: name, symbol: symbol, amount: balance, price: 0, icon: null, address: contract.address, last24Hrs: 0, decimals: decimals });
    }
    this.setState({

      tokens: tks,
    })
  }

  saveName = async () => {
    this.setState({
      infoLoading: true,
    })
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    try {
      const tx = await web3.transactions.buildConfig.setInfoName(wallet, infoChain.chain, this.state.name);
      const error = await web3.transactions.sendTransaction(tx);
      if (error) {
        this.setState({ errorAlert: error })
      } else {
        const output = await web3.transactions.waitConfirmation(tx.hash);
        if (output.status === 'mined' || output.status === 'confirmed') {
          this.setState({ successAlert: tx.hash })
        } else {
          this.setState({ errorAlert: output.output.error })
        }
      }
    } catch (err) {
      this.setState({ errorAlert: err.message })
    }
    this.setState({ infoLoading: false })
  }

  saveBio = async () => {
    this.setState({ infoLoading: true })
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    try {
      const tx = await web3.transactions.buildConfig.setInfoBio(wallet, infoChain.chain, this.state.bio);
      const error = await web3.transactions.sendTransaction(tx);
      if (error) {
        this.setState({ errorAlert: error })
      } else {
        const output = await web3.transactions.waitConfirmation(tx.hash);
        if (output.status === 'mined' || output.status === 'confirmed') {
          this.setState({ successAlert: tx.hash })
        } else {
          this.setState({ errorAlert: output.output.error })
        }
      }
    } catch (err) {
      this.setState({ errorAlert: err.message })
    }
    this.setState({ infoLoading: false })
  }

  importToken = async (address) => {
    const web3 = await Session.getWeb3();
    const infoChain = Session.getInfoChain();

    const name = await web3.contracts.readContract(infoChain.chain, address, 'name', []);
    if (!name) {
      toast.error(`Error - Contract not found`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const req = await Api.post(`contracts`, {
      address: address,
      name: name.output,
      isToken: true,
    });
    if (req.error) {
      toast.error(`Error`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    this.setState({ loading: true })
    await this.updateTokens();
    this.setState({ loading: false })
  }

  saveURL = async () => {
    this.setState({ infoLoading: true })
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    try {
      const tx = await web3.transactions.buildConfig.setInfoUrl(wallet, infoChain.chain, this.state.url);
      const error = await web3.transactions.sendTransaction(tx);
      if (error) {
        this.setState({ errorAlert: error })
      } else {
        const output = await web3.transactions.waitConfirmation(tx.hash);
        if (output.status === 'mined' || output.status === 'confirmed') {
          this.setState({ successAlert: tx.hash })
        } else {
          this.setState({ errorAlert: output.output.error })
        }
      }
    } catch (err) {
      this.setState({ errorAlert: err.message })
    }
    this.setState({ infoLoading: false })
  }

  savePhoto = async () => {
    this.setState({ infoLoading: true })
    const web3 = await Session.getWeb3();
    const wallet = Session.getWallet();
    const infoChain = Session.getInfoChain();
    try {
      const tx = await web3.transactions.buildConfig.setInfoPhoto(wallet, infoChain.chain, this.state.photo);
      const error = await web3.transactions.sendTransaction(tx);
      if (error) {
        this.setState({ errorAlert: error })
      } else {
        const output = await web3.transactions.waitConfirmation(tx.hash);
        if (output.status === 'mined' || output.status === 'confirmed') {
          this.setState({ successAlert: tx.hash })
        } else {
          this.setState({ errorAlert: output.output.error })
        }
      }
    } catch (err) {
      this.setState({ errorAlert: err.message })
    }
    this.setState({ infoLoading: false })
  }

  render() {
    const { intl } = this.props;

    return (<div className="page-padding">
      <Loading loading={this.state.loading} />
      <SweetAlert
        title="Error"
        onConfirm={() => this.setState({ errorAlert: '' })}
        show={this.state.errorAlert !== ''}
        confirmBtnCssClass="sweet-alert-confirm-button"
      >{this.state.errorAlert}</SweetAlert>
      <SweetAlert
        success
        show={this.state.successAlert !== ''}
        title="Success"
        onConfirm={() => this.setState({ successAlert: '' })}
        confirmBtnCssClass="sweet-alert-confirm-button"
      ><strong>TxId: </strong><a href={`${Session.getInfoChain().explorer}/tx/${this.state.successAlert}`} target="_blank" rel="noopener noreferrer">{this.state.successAlert}</a></SweetAlert>
      {!this.state.loading && <div className="Profile-component">
        <div style={Back} className="background" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-4 col-xl-3 profile-left-shade">
              <div>
                <div className="text-center profile-image">
                  <img src={this.state.addressInfo.photo ? this.state.addressInfo.photo : ProfileLockScreen} alt="Name" />
                  <div>
                    <ul className="list-inline pt-20 theme-color">
                      <li className="list-inline-item mr-25">
                        <div style={activeColor}>{this.state.addressInfo.balance} BWS</div>
                        <div>
                          <strong><IntlMessages id="balance" /></strong>
                        </div>
                      </li>
                      <li className="list-inline-item ml-25">
                        <div style={activeColor}>{this.state.addressInfo.txCount}</div>
                        <div>
                          <strong><IntlMessages id="transactions" /></strong>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="pa-10">
                  <div style={activeColor} className="headline">
                    <IntlMessages id="about" />
                  </div>
                  {this.state.addressInfo.name && <div className="theme-color"> <strong><IntlMessages id="name" />: </strong>{this.state.addressInfo.name} </div>}
                  {this.state.addressInfo.bio && <div className="theme-color"> <strong>Bio: </strong>{this.state.addressInfo.bio} </div>}
                  {this.state.addressInfo.url && <div className="theme-color"> <strong>URL: </strong>{this.state.addressInfo.url} </div>}
                </div>
              </div>
            </div>

            <div className="col-md-12 col-lg-8 col-xl-9 profile-right-shade">
              <div className="profile-header-panel pa-10">
                <div className="headline">{this.state.addressInfo.name}</div>
                <div><strong><IntlMessages id="address" />: </strong>{this.state.addressInfo.address}</div>
              </div>

              <div className="pl-10">
                <ul className="list-inline theme-color">
                  <li
                    style={this.state.wallet ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("wallet")}
                  >
                    <IntlMessages id="wallet.wallet" />
                  </li>
                  <li
                    style={this.state.history ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("history")}
                  >
                    <IntlMessages id="wallet.history" />
                  </li>
                  <li
                    style={this.state.info ? profileTabLink : {}}
                    className="list-inline-item profile-list"
                    onClick={() => this.activePanel("info")}
                  >
                    <IntlMessages id="wallet.info" />
                  </li>
                </ul>
              </div>

              <div>
                {this.state.wallet && <>
                  {this.state.tokens.map((token, i) => <div key={`token-${i}`}>
                    <div className="flex-x">
                      <div className="m-auto">
                        <TokenItem token={token} />
                      </div>
                      <div className="flex-1 m-auto">
                        <button className="ml-3 c-btn c-rounded c-success" onClick={() => this.buyAction(token)}><IntlMessages id="buy" /> <i className="fas fa-dollar-sign mr-10" /></button>
                        {(this.state.chain !== 'mainnet' || token.address) ? <button className="ml-3 c-btn c-rounded c-info" onClick={() => this.sendAction(token)}><IntlMessages id="send" /> <i className="fas fa-arrow-right mr-10" /></button> : <></>}
                      </div>
                    </div>
                    <hr />
                  </div>)}
                  <div className="text-center pb-4">
                    <button className="c-btn ma-5 c-rounded c-btn-dashed c-outline-dark" onClick={() => this.setState({ inputPrompt: true })}>
                      <i className="fas fa-plus mr-10" />
                      <IntlMessages id="wallet.import_token" />
                    </button>
                  </div>
                </>}
                {this.state.history && <TransactionsTable txs={this.state.txs} />}
                {this.state.info && <div className="work-card">
                  <div className="Work-header" style={titleStyle}>
                    INFO
                  </div>
                  <div className="work-body">
                    <form className="pa-24">
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Photo</label>
                        <div className="col-sm-8">
                          <FileBase64
                            multiple={false}
                            onDone={(file) => this.setState({ photo: file.base64 })} />
                        </div>
                        <button className="col-sm-2 c-btn c-outline-primary" onClick={this.savePhoto} disabled={this.state.infoLoading}>
                          {this.state.infoLoading && <Spinner color="primary" />}
                          {!this.state.infoLoading && <IntlMessages id="save" />}
                        </button>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Name</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control react-form-input"
                            value={this.state.name}
                            onChange={e => this.setState({ name: e.target.value })}
                          />
                        </div>
                        <button className="col-sm-2 c-btn c-outline-primary" onClick={this.saveName} disabled={this.state.infoLoading}>
                          {this.state.infoLoading && <Spinner color="primary" />}
                          {!this.state.infoLoading && <IntlMessages id="save" />}
                        </button>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">URL</label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control react-form-input"
                            value={this.state.url}
                            onChange={e => this.setState({ url: e.target.value })}
                          />
                        </div>
                        <button className="col-sm-2 c-btn c-outline-primary" onClick={this.saveURL} disabled={this.state.infoLoading}>
                          {this.state.infoLoading && <Spinner color="primary" />}
                          {!this.state.infoLoading && <IntlMessages id="save" />}
                        </button>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label"><IntlMessages id="about" /></label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control react-form-input"
                            value={this.state.bio}
                            onChange={e => this.setState({ bio: e.target.value })}
                          />
                        </div>
                        <button className="col-sm-2 c-btn c-outline-primary" onClick={this.saveBio} disabled={this.state.infoLoading}>
                          {this.state.infoLoading && <Spinner color="primary" />}
                          {!this.state.infoLoading && <IntlMessages id="save" />}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>}
      <SweetAlert
        input
        showCancel
        show={this.state.inputPrompt}
        cancelBtnBsStyle="default"
        confirmBtnCssClass="sweet-alert-confirm-button"
        cancelBtnCssClass="sweet-alert-cancle-button"
        customClass="sweet-alert-wrapper"
        title={intl.formatMessage({ id: `wallet.import_token` })}
        onConfirm={address => {
          this.importToken(address);
          this.setState({ inputPrompt: false })
        }}
        onCancel={() =>
          this.setState({ inputPrompt: false })
        }
        className="input-sweet-alert"
      ><IntlMessages id="wallet.import_token_text" /></SweetAlert>
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default compose(
  withRouter,
  injectIntl,
  connect(mapStateToProps, null)
)(Wallet);