export const sidebarData = [
  {
    name: "wallet.title",
    routepath: "/wallet",
    iconClass: "fas fa-wallet"
  },
  {
    name: "dashboard.title",
    routepath: "/dashboard",
    iconClass: "fas fa-fire"
  },
  {
    name: 'wallet.buy',
    routepath: '/pricing',
    iconClass: "fas fa-dollar-sign",
  },
  {
    name: "web3",
    iconClass: "fas fa-robot",
    child: [
      {
        listname: "ide.title",
        routepath: "/ide",
        iconClass: "fas fa-chalkboard",
      },
    ]
  },
  {
    name: "community",
    routepath: "/community",
    iconClass: "fa fa-heart"
  },
  {
    name: "help_and_support",
    routepath: "/support",
    iconClass: "fa fa-question"
  },
];

export const HorizontalSidebarData = [
];