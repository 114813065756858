import Layout from "layouts/DashboardLayout.jsx";
import {
  Buy,
  Recover,
  Error400,
  Error500,
  Signup,
  Signin,
} from "./../views/pages/index";

const indexRoutes = [
  { path: "/recover", component: Recover },
  { path: "/signin", component: Signin },
  { path: "/signup", component: Signup },
  { path: "/error400", component: Error400 },
  { path: "/error500", component: Error500 },
  { path: "/pricing", component: Buy },
  { path: "/", component: Layout }
];

export default indexRoutes;
