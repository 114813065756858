import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import PageTitle from "components/common/PageTitle";
import createGlobe from 'cobe';
import Feed from "components/widgets/feed/Feed";
import IntlMessages from "util/intlMessages";
import DefaultTable from "components/widgets/defaultTable/DefaultTable";
import Auth from "api/auth";

const Dashboard = (props) => {
  const canvasRef = useRef();
  const info = Auth.getInfo();

  useEffect(() => {
    let phi = 0;

    const globe = createGlobe(canvasRef.current, {
      devicePixelRatio: 2,
      width: 500 * 2,
      height: 500 * 2,
      phi: 0,
      theta: 0,
      dark: 1,
      diffuse: 1.2,
      mapSamples: 16000,
      mapBrightness: 6,
      baseColor: [0.522, 0.365, 0.988],
      markerColor: [0.129, 0.949, 0.98],
      glowColor: [0.129, 0.949, 0.98],
      opacity: 0.8,
      markers: [
        // longitude latitude
        { location: [37.7595, -122.4367], size: 0.1 },
        { location: [40.7128, -74.006], size: 0.1 },
        { location: [-47.91 - 15.78], size: 0.1 },
        { location: [-15.7797200, -47.9297218], size: 0.1 },
        { location: [-27.5966667 - 48.5491676], size: 0.1 },
        { location: [39.9611755, -82.9987946], size: 0.1 },
        { location: [45.5234515, -122.6762085], size: 0.1 },
        { location: [45.5167792, -73.649177], size: 0.1 },
        { location: [35.6895266, 139.6916809], size: 0.1 },
        { location: [48.8534100, 2.3487999], size: 0.1 },
        { location: [51.5084153, -0.1255327], size: 0.1 },
        { location: [50.1166667, 8.6833334], size: 0.1 },
      ],
      onRender: (state) => {
        // Called on every animation frame.
        // `state` will be an empty object, return updated params.
        state.phi = phi;
        phi += 0.005;
      }
    });

    return () => {
      globe.destroy();
    };
  }, []);

  return (
    <div className="page-padding">
      <PageTitle title="dashboard.title" />

      <div className="plr-15">
        <div className="row">
          <div className="col-md-8 mtb-10">
            <div className="card roe-shadow-2 fill-height">
              <div className="card-body">
                <div className="flex-x align-center mb-9">
                  <h4 className="mb-0 fs-18 header"><IntlMessages id="dashboard.connected_nodes" /></h4>
                </div>
                <canvas
                  ref={canvasRef}
                  style={{ width: 500, height: 500, maxWidth: "100%", aspectRatio: 1 }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-4 mtb-10">
            <Feed />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mtb-10">
            <DefaultTable
              id="chains"
              actions={<button className="c-btn c-primary" onClick={() => props.history.push('/support')}>
                <IntlMessages id="dashboard.new_networks" />
              </button>}
              title={<IntlMessages id="dashboard.my_networks" />}
              data={{
                headerFields: [
                  <IntlMessages id="name" />,
                  'Chain ID',
                  <IntlMessages id="nodes" />,
                  'Status'
                ],
                rowData: info.chains.map(chainInfo => ([chainInfo.name, chainInfo.chain, chainInfo.nodes.length + (chainInfo.chain === 'mainnet' ? 24 : 0), <strong className="c-text-success">Online</strong>])),
              }} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mtb-10">
            <DefaultTable
              id="nodes"
              actions={<button className="c-btn c-primary" onClick={() => props.history.push('/support')}>
                <IntlMessages id="dashboard.new_node" />
              </button>}
              title={<IntlMessages id="dashboard.my_nodes" />}
              data={{
                headerFields: [],
                rowData: [],
              }}
              emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                <h1>Nenhum nó criado ainda</h1>
                <p>Crie um ou mais nós bywise para poder criar sua própria blockchain.</p>
                <p>Caso tenha dificuldades, leia esse tutorial <a href="https://tawk.to/chat/6368e1c7daff0e1306d61e71/1gh8r3tog">aqui</a> ou entre em contato com o <a href="/support">suporte</a>.</p>
              </div>} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mtb-10">
            <DefaultTable
              id="web3"
              title={<IntlMessages id="dashboard.my_contracts" />}
              actions={<button className="c-btn c-primary" onClick={() => props.history.push('/ide')}>
                Criar uma nova aplicação Web3
              </button>}
              data={{
                headerFields: [],
                rowData: [],
              }}
              emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                <h1>Aplicações Web3</h1>
                <p>Crie aplicações web3 de maneira simplificada utilizando javascript.</p>
                <p>Leia esse tutorial rápido <a href="https://tawk.to/chat/6368e1c7daff0e1306d61e71/1gh8r3tog">aqui</a> e conheça nosso editor <a href="/ide">aqui</a>.</p>
              </div>} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mtb-10">
            <DefaultTable
              id="traceability"
              title={<IntlMessages id="dashboard.traceability" />}
              actions={<button className="c-btn c-primary" onClick={() => props.history.push('/support')}>
                Criar Nova Chave de API
              </button>}
              data={{
                headerFields: [],
                rowData: [],
              }}
              emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                <h1>Rastreabilidade utilizando blockchain</h1>
                <p>Utilize a blockchain da Bywise para integrar a rastreabilidade bens e serviços.</p>
                <p>Uma vez na blockchain os dados são imutáveis. Crie permissões com níveis de acesso para dar mais transparencia para todos seus parceiros.</p>
                <p>A integração é simplificada, bastando fazer algumas requisições REST API na sua aplicação.</p>
                <p>Leia esse tutorial rápido <a href="https://tawk.to/chat/6368e1c7daff0e1306d61e71/1gh8r3tog">aqui</a> ou entre em contato com nosso <a href="/support">suporte</a>.</p>
              </div>} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 mtb-10">
            <DefaultTable
              id="tokens"
              title="Tokens"
              actions={<button className="c-btn c-primary" onClick={() => props.history.push('/wallet')}>
                Criar Ou Importar Token
              </button>}
              data={{
                headerFields: [],
                rowData: [],
              }}
              emptyWarn={<div className="text-center grey--text pt-4 pb-4">
                <h1>Bywise Wallet</h1>
                <p>Utilize a blockchain da Bywise para tornar seu token compatível com todas as blockchains existentes.</p>
                <p>Através de uma interface simples, os usuários podem criar e movimentar seus tokens por diversas blockchains como Ethereum, Polygon, Binance Smart Chain entre outras.</p>
                <p>Leia esse tutorial rápido <a href="https://tawk.to/chat/6368e1c7daff0e1306d61e71/1gh8r3tog">aqui</a> ou entre em contato com nosso <a href="/support">suporte</a>.</p>
              </div>} />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger
  };
};

export default connect(mapStateToProps, null)(Dashboard);
