import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { loginBack, bywiseIcon } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import Api from "api/api";

const { login } = AuthActions;

const loginContainer = {
  backgroundImage: `url(${loginBack})`,
  backgroundPosition: "center center",
  backgroundSize: "cover",
  position: "fixed",
  overflow: "auto",
  top: 0,
  bottom: 0
};

const Recover = props => {
  const [tab, setTab] = useState(0);
  const [email, setEmail] = useState("");
  const [emailCode, setEmailCode] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState({});

  const isValid = () => {
    if (password.length < 8 && tab === 2) {
      setError({
        password: <IntlMessages id="recover.password_too_short" />
      });
      return false;
    }
    if (password !== password2 && tab === 2) {
      setError({
        password2: <IntlMessages id="recover.password_dont_match" />
      });
      return false;
    }
    if (!emailCode.match(/[0-9]{6}/) && tab === 1) {
      setError({
        emailCode: <IntlMessages id="recover.invalid_email_code" />
      });
      return false;
    }
    setError({});
    return true;
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    let req;
    if (isValid()) {
      if (tab === 0) {
        req = await Api.post(`auth/password-reset/request`, {
          email: email,
        }, false);
        if (req.error) {
          setError({
            email: <IntlMessages id="recover.invalid_email" />
          });
        } else {
          setTab(1);
        }
      } else if (tab === 1) {
        req = await Api.post(`auth/password-reset/test`, {
          sentTo: email,
          code: emailCode,
        }, false);
        if (req.error) {
          setError({
            emailCode: <IntlMessages id="recover.invalid_email_code" />
          });
        } else {
          setTab(2);
        }
      } else if (tab === 2) {
        req = await Api.post(`auth/password-reset/set`, {
          sentTo: email,
          code: emailCode,
          password: password,
        });
        if (!req.error) {
          props.history.push("/signin");
        }
      }
    }
  };

  const Error = props => {
    const field1 = props.field;
    if (error[field1]) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {error[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <div>
        {tab === 0 && (
          <>
            <div className="form-container">
              <div className="login-icon">
                <img src={bywiseIcon} alt="icon" height="100px" />
              </div>
              <div className="login-title mb-2">
                <IntlMessages id="recover.title" />
              </div>
              <form className="pa-24">
                <div className="form-group">
                  <label>
                    <IntlMessages id="recover.email" />
                  </label>
                  <input
                    type="email"
                    className="form-control react-form-input"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <Error field="email" />
                </div>

                <button className="btn form-button mt-16" onClick={handleRegister}>
                  <IntlMessages id="next" />
                </button>

                <div
                  className="text-center link-label"
                  onClick={() => props.history.push("/signup")}
                >
                  <IntlMessages id="signin.signup" />
                </div>
              </form>
            </div>
          </>
        )}
        {tab === 1 && (
          <>
            <div className="form-container">
              <form className="pa-24">
                <button
                  type="submit"
                  className="btn form-back p-3"
                  onClick={() => setTab(0)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>

                <div className="login-title mb-4">
                  <IntlMessages id="recover.email_confirm" />
                </div>

                <div className="form-info-text mt-16">
                  <IntlMessages id="recover.email_confirm_text" />
                </div>
                <div className="form-info-text mt-16">
                  <IntlMessages id="recover.email_confirm_text2" />
                </div>

                <div className="form-group mt-16">
                  <label>
                    <IntlMessages id="recover.email_confirm" />
                  </label>
                  <input
                    type="text"
                    className="form-control react-form-input"
                    value={emailCode}
                    placeholder="000000"
                    onChange={e => setEmailCode(e.target.value)}
                  />
                  <Error field="emailCode" />
                </div>

                <button className="btn form-button" onClick={handleRegister}>
                  <IntlMessages id="connect" />
                </button>
              </form>
            </div>
          </>
        )}
        {tab === 2 && (
          <>
            <div className="form-container">
              <form className="pa-24">
                <button
                  type="submit"
                  className="btn form-back p-3"
                  onClick={() => setTab(0)}
                >
                  <i className="fas fa-arrow-left"></i>
                </button>

                <div className="login-title mb-4">
                  <IntlMessages id="recover.new_password" />
                </div>

                <div className="form-group">
                  <label>
                    <IntlMessages id="recover.password" />
                  </label>
                  <input
                    type="password"
                    className="form-control react-form-input"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                  <Error field="password" />
                </div>

                <div className="form-group">
                  <label>
                    <IntlMessages id="recover.rewrite_password" />
                  </label>
                  <input
                    type="password"
                    className="form-control react-form-input"
                    value={password2}
                    onChange={e => setPassword2(e.target.value)}
                  />
                  <Error field="password2" />
                </div>

                <button className="btn form-button" onClick={handleRegister}>
                  <IntlMessages id="connect" />
                </button>
              </form>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default compose(
  injectIntl,
  withRouter,
  connect(null, { login })
)(Recover);
