import { getUptime } from "helper/methods";
import React from "react";
import { Table } from 'reactstrap';
import Session from "util/Session";
import BigNumber from "bignumber.js";

const TransactionsTable = props => {
  const { txs } = props;

  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Hash</th>
          <th>Amount</th>
          <th>Fee</th>
          <th>From</th>
          <th>To</th>
          <th>Age</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {txs.map(tx => <tr key={tx.hash}>
          <td><a href={`${Session.getInfoChain().explorer}/tx/${tx.hash}`} target="_blank" rel="noopener noreferrer">{tx.hash.substring(0, 10)}...</a></td>
          <td>{tx.amount.reduce((n, i) => (new BigNumber(n)).plus(i), new BigNumber(0)).toString()}</td>
          <td>{tx.fee}</td>
          <td><a href={`${Session.getInfoChain().explorer}/address/${tx.from[0]}`} target="_blank" rel="noopener noreferrer">{tx.from[0].substring(0, 10)}...</a></td>
          <td><a href={`${Session.getInfoChain().explorer}/address/${tx.to[0]}`} target="_blank" rel="noopener noreferrer">{tx.to[0].substring(0, 10)}...</a></td>
          <td>{getUptime(tx.created)}</td>
          <td>{tx.status}</td>
        </tr>)}
      </tbody>
    </Table>
  );
};

export default TransactionsTable
  ;